<template>
    <div class="profile-details profile-details-dropdown">
        <ul>
            <li
                v-for="(triple, tripleIndex) in mainPredicates"
                :key="tripleIndex"
            >
                <div
                    v-if="triple.predicate == 'latAndLong'"
                    style="height: 100px; width: 100%"
                >
                    <l-map
                        ref="dropdown-map"
                        id="dropdown-map"
                        v-model:zoom="zoom"
                        :center="triple.object"
                        :options="{
                            dragging: false,
                            zoomControl: false,
                            scrollWheelZoom: false,
                            doubleClickZoom: false,
                            boxZoom: false,
                            attributionControl: false,
                        }"
                    >
                        <l-tile-layer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                            layer-type="base"
                            attribution="Tiles &copy; Esri"
                        ></l-tile-layer>
                        <l-marker :lat-lng="triple.object"></l-marker>
                    </l-map>
                </div>
                <div v-if="triple.predicate == 'has note'" class="pro-details-box">
                    <span class="property-label">{{ $t("EntityDetailsDropdown.summary") }}</span>
                    <p>
                        {{ triple.object }}
                    </p>
                </div>
                <div v-if="triple.predicate == 'Dates'" class="pro-details-box">
                    <span class="property-label">{{ $t("EntityDetailsDropdown.dates") }}</span>
                    <p>
                        {{ $t("EntityDetailsDropdown.birth") }}:
                        {{ triple["birthDate"] ?? "Unknown" }},
                        {{ triple["birthPlace"] ?? "Unknown" }}
                        <br />
                        {{ $t("EntityDetailsDropdown.death") }}:
                        {{ triple["deathDate"] ?? "Unknown" }},
                        {{ triple["deathPlace"] ?? "Unknown" }}
                    </p>
                </div>
                <div v-if="triple.predicate == 'Created'" class="pro-details-box">
                    <span class="property-label">{{ $t("EntityDetailsDropdown.created") }}</span>
                    <p>
                        {{ triple["createDate"] ?? "Unknown" }},
                        {{ triple["createPlace"] ?? "Unknown" }}
                    </p>
                </div>
                <div 
                    v-if="
                        triple.predicate == 'pursuit' ||
                        triple.predicate == 'attribute' ||
                        triple.predicate == 'work' ||
                        triple.predicate == 'bornHere' ||
                        triple.predicate == 'diedHere' ||
                        triple.predicate == 'type' ||
                        triple.predicate == 'member' ||
                        triple.predicate == 'group' ||
                        triple.predicate == 'published' ||
                        triple.predicate == 'creator'
                    "
                    class="pro-details-box">
                    <span class="property-label">{{ $t("EntityDetailsDropdown." + triple.predicate.replaceAll(' ', '_')) }}</span>
                    <p class="truncate-text">
                        {{ triple.object }}
                    </p>
                </div>
            </li>
            <li class="pro-details-box" v-if="datasets.length > 0">
                <span class="property-label">{{ $t("EntityDetailsDropdown.dataset") }}</span>
                <p  v-for="(dataset, datasetIndex) in datasets" :key="datasetIndex" class="truncate-text" :title="dataset.name ?? dataset.uri">
                    {{ dataset.name ?? dataset.uri }}
                </p>
            </li>
            <li class="pro-details-box">
                <span class="property-label">{{ $t("EntityDetailsDropdown.identifier") }}</span>
                <a
                    class="url-link truncate-text"
                    :href="
                        'https://rs-review.lincsproject.ca/resource/?uri=' +
                        data.mainEntityInfo.resource
                    "
                    target="_blank"
                    :title="data.mainEntityInfo.resource"
                    >{{ data.mainEntityInfo.resource
                    }}<img
                        class="img-fluid external-link"
                        src="@/assets/external-link.svg"
                        alt="External link"
                /></a>
            </li>
            <li>
                <button
                    class="pro-details-box view-more-button"
                    tabindex="0"
                    role="link"
                    v-on:click="$emit('update')"
                >
                    <span class="url-link view-more-text">{{
                        $t("EntityDetailsDropdown.view_more")
                    }}</span>
                </button>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { LMap, LMarker, LTileLayer } from "@vue-leaflet/vue-leaflet";
@Options({
    props: {
        data: Object,
    },
    emits: ["update"],
    components: {
        LMap,
        LMarker,
        LTileLayer,
    },
})
export default class EntityDetailsDropdown extends Vue {
    data!: any;
    zoom = 8;
    datasets: any = [];

    // This is the url for filling out the dataset labels
    apiURL = 
        "https://lincs-api.lincsproject.ca/api/dataset";

    // This is the authToken needed for the lincs-api's rate limiting
    authToken = 
        "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJVcG5aOHZaTGNUemhTMWtzdGN2NDVkR205QXYwUmlTbElaQ0YzZEhxR1JRIn0.eyJleHAiOjE3NDIzMjMyMjcsImlhdCI6MTcxMDc4NzIyNywianRpIjoiMjU2OTU1YjUtOTkzNi00OGZmLThjMmMtYjdkOWRiMTliZjc0IiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5saW5jc3Byb2plY3QuY2EvcmVhbG1zL2xpbmNzIiwiYXVkIjpbInZlcnNkLXVpIiwicmVzZWFyY2hzcGFjZSIsImFkbWluLWNsaSIsImJyb2tlciIsImFjY291bnQiXSwic3ViIjoiYmQ5MTRkMTEtZmVkMi00ODllLWI0MGQtNDk0YjkzZDFmOGU4IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibGluY3MtYXBpIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyIvKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiVXNlciIsImRlZmF1bHQtcm9sZXMtbGluY3MiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsidmVyc2QtdWkiOnsicm9sZXMiOlsidXNlciJdfSwicmVzZWFyY2hzcGFjZSI6eyJyb2xlcyI6WyJjb250cmlidXRvciIsImd1ZXN0Il19LCJhZG1pbi1jbGkiOnsicm9sZXMiOlsiYWRtaW4iXX0sImJyb2tlciI6eyJyb2xlcyI6WyJyZWFkLXRva2VuIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6ImVtYWlsIHByb2ZpbGUgYXZhdGFyX3VybCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY2xpZW50SG9zdCI6IjcwLjc1LjExMi4xMSIsInByZWZlcnJlZF91c2VybmFtZSI6InNlcnZpY2UtYWNjb3VudC1saW5jcy1hcGkiLCJjbGllbnRBZGRyZXNzIjoiNzAuNzUuMTEyLjExIiwiY2xpZW50X2lkIjoibGluY3MtYXBpIn0.ivjIKG0hWe5ULAseATO4RYUmR8GCEZaTnbE70zoT-xdNxz-jXZcGmTLnGf_t34X3BEX13u-unCPGv7T5M5Wnn6f_ohG5WW8H18oSxiAflSLue3TEpgEODACXS2rzP8gDODof2cC1MSDL9E_YI4lpQ6XAl8aMb-ojhzjZ4RJUbjXq3epQ-rmvg2R5DYtKSPtn8f1o2zooGVZg8lO_Ymi9tAxqr7PsayMXExsD2R_SShrUD9DS-1Vfvrh8a1dxdquUtZ1GHc90Ms08BxdV8hBe0Q8UvQorZgQ6dOUnKUId6250qcENqXjvbttN--FPt4z8BZDphaA38Itm-q0UV4YesQ";


    // Gets a list of unique predicates for a given entity
    get mainPredicates() {
        function onlyUnique(value: any, index: any, array: any) {
            const map = array.map((pred: any) => pred.predicate);
            return map.indexOf(value.predicate) === index;
        }

        const allPreds = this.data.mainEntityInfo.outgoing == null 
            ? [] 
            : this.data.mainEntityInfo.outgoing.map(
                (pred: any) => ({
                    predicate: pred.predicateLabel ?? pred.predicate,
                    object: pred.object,
                    objectLabel: pred.objectLabel,
                    graph: pred.graph,
                }),
            );

        for (let i = 0; i < allPreds.length; i++) {
            const dataset = allPreds[i].graph;
            if (dataset && !this.datasets.map((dataset: any) => dataset.uri).includes(dataset)) {
                const datasetObj = {
                    uri: dataset,
                };
                this.datasets.push(datasetObj);
                this.getDatasetLabel(datasetObj);
            }

            if (allPreds[i].parsed) {
                continue;
            } else if (
                allPreds[i].predicate == "birthDate" ||
                allPreds[i].predicate == "deathDate" ||
                allPreds[i].predicate == "birthPlace" ||
                allPreds[i].predicate == "deathPlace"
            ) {
                const newPred = {
                    predicate: "Dates",
                    parsed: true
                };
                for (let j = i; j < allPreds.length; j++) {
                    if (
                        allPreds[j].predicate == "birthDate" ||
                        allPreds[j].predicate == "deathDate" ||
                        allPreds[j].predicate == "birthPlace" ||
                        allPreds[j].predicate == "deathPlace"
                    ) {
                        (newPred as any)[allPreds[j].predicate] =
                            allPreds[j].objectLabel;

                        allPreds.splice(j, 1);
                        j--;
                    }
                }
                i--;
                allPreds.push(newPred);
            } else if (
                allPreds[i].predicate == "createDate" ||
                allPreds[i].predicate == "createPlace"
            ) {
                const newPred = {
                    predicate: "Created",
                    parsed: true
                };
                for (let j = i; j < allPreds.length; j++) {
                    if (
                        allPreds[j].predicate == "createDate" ||
                        allPreds[j].predicate == "createPlace"
                    ) {
                        (newPred as any)[allPreds[j].predicate] =
                            allPreds[j].objectLabel;

                        allPreds.splice(j, 1);
                        j--;
                    }
                }
                i--;
                allPreds.push(newPred);
            } else if ( 
                allPreds[i].predicate == "pursuit" ||
                allPreds[i].predicate == "attribute" ||
                allPreds[i].predicate == "work" ||
                allPreds[i].predicate == "bornHere" ||
                allPreds[i].predicate == "diedHere" ||
                allPreds[i].predicate == "type" ||
                allPreds[i].predicate == "member" ||
                allPreds[i].predicate == "group" ||
                allPreds[i].predicate == "published" ||
                allPreds[i].predicate == "creator"
            ) {
                const searchPred = allPreds[i].predicate;
                let str = "";

                for (let j = i; j < allPreds.length; j++) {
                    if (allPreds[j].predicate == searchPred) {
                        str += allPreds[j].objectLabel + "; ";

                        allPreds.splice(j, 1);
                        j--;
                    }
                }
                i--;

                str = str.substring(0, str.length - 2);
                const newPred = {
                    predicate: searchPred,
                    object: str,
                    parsed: true
                };
                allPreds.push(newPred);
            } else if (this.assignOrderValue(allPreds[i]) == 15) { // If not a displayed prop, remove it from render
                allPreds.splice(i, 1);
                i--;
            }
        }

        return this.orderMainPredicates(allPreds.filter(onlyUnique));
    }

    orderMainPredicates(allPreds: any) {
        return allPreds.sort((a: any, b: any) => {
            return this.assignOrderValue(a) - this.assignOrderValue(b);
        });
    }

    assignOrderValue(triple: any) {
        switch(triple.predicate) {
            case 'latAndLong':
                return 1;
            case 'has note':
                return 2;
            case 'type':
                return 3;
            case 'Dates':
                return 4;
            case 'bornHere':
                return 5;
            case 'member':
                return 6;
            case 'group':
                return 7;
            case 'creator':
                return 8;
            case 'pursuit':
                return 9;
            case 'diedHere':
                return 10;
            case 'published':
                return 11;
            case 'Created':
                return 12;
            case 'attribute':
                return 13;
            case 'work':
                return 14;
            default:
                return 15;
        }
    }

    getDatasetLabel(datasetObj: any) {
        fetch(this.apiURL, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': this.authToken,
            },
            method: "POST",
            body: JSON.stringify({
                uri: datasetObj.uri,
                language: this.$i18n.locale
            }),
        })
        .then((response) => {
            if (response.ok) {
            return response.json();
            }
            throw new Error("FAILURE");
        })
        .then((response) => {
            for (const pred of response) {
                if (pred.predicate == "http://purl.org/dc/terms/title") {
                    datasetObj.name = pred.object;
                    break;
                }
            }
        })
        .catch(() => {
            console.log("Query Error");
        });
    }
}
</script>
<style>
ul {
    list-style-type: none;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.profile-details-dropdown {
    background-color: #083943;
    border-radius: 0px 0px 4px 4px !important;
}

.url-link {
    color: white;
    display: block;
    text-decoration: none;
    font-size: 14px;
}

.url-link .external-link {
    width: 14px;
    margin-left: 5px;
}

.profile-section.light-bg .url-link {
    color: #107386;
}

.property-label {
    color: #BAD8DE;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.high-tone span {
    cursor: pointer;
}

.view-more-button {
    width: 95%;
    margin: 5px 0px 12px 8px;
    padding: 8px 16px !important;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
}

.view-more-button:hover {
    background-color: #E2F0F3;
}

.view-more-text {
    color: #083943;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}
</style>
