export const getPropertyLabel = (_this: any, label: string) => {
    switch(label) {
        case "birthPlace":
        case "birthDate":
        case "deathPlace":
        case "deathDate":
            return "Dates (birth/death date + place)";
        case "pursuit":
        case "attribute":
        case "work":
        case "bornHere":
        case "diedHere":
        case "type":
        case "member":
        case "group":
        case "published":
        case "creator":
            return _this.$t("EntityDetailsDropdown." + label);
        default:
            return label;
    }
};