import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stitle-text profile-avtar" }
const _hoisted_2 = { class: "avtar-img" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "profile-details" }
const _hoisted_5 = { class: "icon-btn-group" }
const _hoisted_6 = ["aria-expanded"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            'site-title-text sub-title': true,
            'main-entity': _ctx.firstEntity,
            'sub-child':
                _ctx.firstEntity || (_ctx.index < 2 && !(_ctx.index == 1 && _ctx.annotationsView)),
            'show-details-expanded': _ctx.showDetailsIndex === _ctx.index
        }),
    style: _normalizeStyle(_ctx.childEntityStyles(_ctx.index))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          class: "img-fluid",
          src: _ctx.entity.mainEntityInfo.image,
          alt: 
                        (_ctx.entity.mainEntityInfo.typeLabel ?? _ctx.entity.mainEntityInfo.type)  + ' icon'
                    
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", {
          class: _normalizeClass({
                        'two-line-ellipsis': true,
                        'sub-child':
                            _ctx.firstEntity ||
                            (_ctx.index < 2 && !(_ctx.index == 1 && _ctx.annotationsView)),
                        'auto-width': !_ctx.firstEntity && _ctx.index > 1,
                    })
        }, _toDisplayString(_ctx.entity.mainEntityInfo.resourceLabel
                            ? _ctx.entity.mainEntityInfo.resourceLabel
                            : _ctx.entity.mainEntityInfo.resource), 3),
        _createElementVNode("span", {
          class: _normalizeClass({
                        'sub-child':
                            _ctx.firstEntity ||
                            (_ctx.index < 2 && !(_ctx.index == 1 && _ctx.annotationsView)),
                    })
        }, _toDisplayString(_ctx.entity.mainEntityInfo.typeLabel), 3)
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        role: "button",
        "aria-label": "Toggle main entity details",
        "aria-expanded": _ctx.showDetailsIndex == _ctx.index,
        title: "Toggle main entity details",
        class: _normalizeClass({
                    'panel-button icon-btn': true,
                    'border-btn': _ctx.showDetailsIndex == _ctx.index,
                }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$parent as any).handleToggleOfDetails(_ctx.index, true)))
      }, _toDisplayString(_ctx.$t("EntityBanner.details")), 11, _hoisted_6)
    ], 512), [
      [_vShow, 
                _ctx.firstEntity || (_ctx.index < 2 && !(_ctx.index == 1 && _ctx.annotationsView))
            ]
    ])
  ], 6))
}